<template>
  <v-app style="background: #fafafa">
    <v-main>
      <app-navbar :pageRoute="pageRoute"></app-navbar>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Expansion from './components/AppNavBar.vue';

export default {
  name: 'App',
  components: {
    'app-navbar': Expansion,
  },
  data: () => ({
    pageRoute: null,
  }),
  watch: {
    $route(to) {
      this.pageRoute = to.name;
    },
  },
};
</script>
