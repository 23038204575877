<template>
  <v-app-bar absolute :elevate-on-scroll="true" elevation="10" color="white">
    <v-btn
      v-if="pageRoute !== 'Dashboard'"
      text
      fab
      small
      @click="navigateBackURL"
      ><v-icon>mdi-chevron-left</v-icon></v-btn
    >

    <v-toolbar-title
      style="display: flex; justify-content: center; padding-left: 0px"
    >
      <img
        src="../assets/img/logo-01.png"
        class="logostyle"
        alt="logo"
        @click="reloadPage()"
      />
    </v-toolbar-title>
    <template
      v-if="
        !['Dashboard', 'Payment', 'Make Payment', 'Terms'].includes(
          pageRoute
        ) && availWidth > 600
      "
      v-slot:extension
    >
      <div
        style="
          justify-content: center;
          display: flex;
          align-items: center;
          padding-top: 10px;
        "
      >
        <v-row>
          <v-col
            class="col col-xs-6 col-sm-3 col-md-3 col-lg-3"
            style="padding-top: 20px"
          >
            <v-combobox
              solo
              color="yellow"
              :items="countries"
              item-text="name"
              item-value="name"
              :search-input.sync="search"
              hide-selected
              label="Location"
              append-icon="mdi-earth"
              persistent-hint
              @change="getCitiesByCountry($event)"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching
                      <strong style="margin-left: 5px"> {{ search }}</strong>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
          <v-col
            class="col col-xs-6 col-sm-3 col-md-3 col-lg-3"
            style="padding-top: 20px"
          >
            <v-combobox
              solo
              color="yellow"
              :items="selectedCities"
              item-text="name"
              item-value="name"
              hide-selected
              label="City "
              append-icon="mdi-earth"
              persistent-hint
              @change="getCityName($event)"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching
                      <strong style="margin-left: 5px"> {{ search }}</strong>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
          <v-col
            class="col col-xs-10 col-sm-4 col-md-4 col-lg-4"
            style="padding-top: 20px"
          >
            <v-menu
              ref="startMenu"
              :close-on-content-click="false"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-text-field
                    solo
                    v-model="dateRangeText"
                    label="Check In / Out"
                    append-icon="mdi-calendar"
                    readonly
                    color="yellow darken-1"
                  ></v-text-field>
                </div>
              </template>

              <v-date-picker
                color="amber darken-1"
                v-model="dateRange"
                :min="new Date().toISOString().substr(0, 10)"
                no-title
                scrollable
                range
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="amber darken-1"
                  @click="$refs.startMenu.isActive = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="amber darken-1"
                  @click="$refs.startMenu.save(dateRange)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            class="stylebutton col col-xs-2 col-sm-2 col-md-2 col-lg-2"
            style="padding-top: 25px"
          >
            <v-btn small class="yellow darken-1" @click="searchCountry" fab>
              <v-icon color="white">mdi-magnify</v-icon>
            </v-btn></v-col
          >
        </v-row>
      </div>
    </template>
    <v-spacer></v-spacer>
    <v-btn v-if="availWidth < 600" text small @click="filterDialog = true">
      <v-icon class="material-icons-outlined iconhover">mdi-magnify</v-icon>
    </v-btn>

    <!-- Mobile Screen
        Search / filter -->
    <v-dialog v-model="filterDialog" fullscreen>
      <v-card>
        <v-toolbar flat>
          <v-btn icon @click="filterDialog = false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Filter</v-toolbar-title>
        </v-toolbar>
        <v-card-text style="padding: 10px">
          <v-container style="margin-top: 20px">
            <v-row>
              <v-col cols="6" xs="12" sm="12" md="12" lg="12">
                <v-combobox
                  outlined
                  :items="countries"
                  item-text="name"
                  item-value="name"
                  :search-input.sync="search"
                  hide-selected
                  label="Location"
                  prepend-icon="mdi-map-marker"
                  persistent-hint
                  @change="getCitiesByCountry($event)"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          No results matching
                          <strong style="margin-left: 5px">
                            {{ search }}</strong
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="6" xs="12" sm="12" md="12" lg="12">
                <v-combobox
                  outlined
                  :items="selectedCities"
                  item-text="name"
                  item-value="name"
                  hide-selected
                  label="City "
                  prepend-icon="mdi-home-city"
                  persistent-hint
                  @change="getCityName($event)"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          No results matching
                          <strong style="margin-left: 5px">
                            {{ search }}</strong
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>

              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                style="padding-top: 0px; margin-top: -10px"
              >
                <v-menu
                  ref="startMenu"
                  :close-on-content-click="true"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-text-field
                        outlined
                        v-model="dateRangeText"
                        label="Check In / Out"
                        prepend-icon="mdi-calendar"
                      ></v-text-field>
                    </div>
                  </template>

                  <v-date-picker
                    color="amber darken-1"
                    v-model="dateRange"
                    :min="new Date().toISOString().substr(0, 10)"
                    no-title
                    scrollable
                    range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="amber darken-1"
                      @click="$refs.startMenu.isActive = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="amber darken-1"
                      @click="$refs.startMenu.save(dateRange)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                style="margin-top: -15px"
              >
                <v-btn block @click="searchCountry()" color="yellow darken-1">
                  <v-icon style="margin-left: 5px">mdi-magnify</v-icon> Search
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-btn
      v-if="availWidth > 600 ? true : false"
      text
      rounded
      style="text-transform: none; font-weight: bold"
      :href="adminLink"
      >Become a host</v-btn
    >
    <v-btn v-if="!user" text icon rounded @click="otpBasedDialog = true"
      ><v-icon class="material-icons-outlined iconhover">
        phone_iphone
      </v-icon></v-btn
    >

    <div style="padding-left: 30px; margin-right: 10px">
      <v-row justify="center">
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              v-bind="attrs"
              v-on="on"
              color="yellow darken-1"
              size="36"
            >
              <span v-if="user" class="white--text text-h6">{{
                user.name.charAt(0).toUpperCase()
              }}</span>
              <v-icon v-else color="white">mdi-account-circle</v-icon>
            </v-avatar>
          </template>
          <v-list>
            <v-list-item
              class="iconhover2"
              v-for="(item, i) in menus.filter((menu) => {
                if (user) {
                  return menu.userLoggedInReq;
                } else {
                  return !menu.userLoggedInReq;
                }
              })"
              :key="i"
              @click="item.onClick"
            >
              <a :href="item.href" style="color: black">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </a>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </div>

    <!-- OTP Dialog -->
    <v-dialog v-model="otpBasedDialog" persistent max-width="500px">
      <v-card>
        <v-card-title style="padding: 15px">
          <h5 class="text-h5">Login With Phone Number</h5>
        </v-card-title>
        <v-stepper v-model="e6" vertical>
          <v-stepper-step step="1" v-model="number" color="yellow darken-1">
            Choose your Country/Region
            <small style="margin-top: 5px">Enter Your Mobile Number</small>
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-card color="white" elevation="0">
              <v-row>
                <v-col
                  cols="3"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <vue-country-code
                    @onSelect="onSelect"
                    v-model="mobileDialCode"
                    :preferredCountries="['vn', 'us', 'gb']"
                  >
                  </vue-country-code>
                </v-col>
                <v-col cols="9">
                  <validation-provider
                    v-slot="{ errors }"
                    id="Mobile"
                    ref="mobileNumber"
                    name="Mobile"
                    v-model="mobile"
                    :rules="{
                      required: true,
                      regex:
                        /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                    }"
                  >
                    <v-text-field
                      v-model="mobile"
                      type="number"
                      :error-messages="errors"
                      prepend-icon="mdi-phone"
                      color="yellow darken-1"
                      label="Phone Number"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card>
            <div
              style="margin-bottom: 10px; margin-top: 20px; text-align: right"
            >
              <v-btn
                color="yellow darken-1"
                text
                small
                @click="dismissOtpBasedDialog"
              >
                Cancel
              </v-btn>
              <v-btn color="yellow darken-1" @click="nextStepLogin">
                Continue
              </v-btn>
            </div>
          </v-stepper-content>
          <v-stepper-step :complete="e6 > 2" step="2" color="yellow darken-1">
            OTP
            <small style="margin-top: 5px">Check your phone for OTP</small>
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card elevation="0">
              <v-text-field
                label="OTP"
                v-model="otp"
                prepend-icon="lock"
                color="yellow darken-1"
                type="number"
              />
            </v-card>
            <div
              style="margin-bottom: 10px; margin-top: 10px; text-align: right"
            >
              <v-btn
                color="yellow darken-1"
                text
                small
                @click="dismissOtpBasedDialog()"
              >
                Cancel
              </v-btn>
              <v-btn
                color="yellow darken-1"
                @click="(e6 = 2), verifyOtp(), (otpBasedDialog = false)"
              >
                Continue
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>

    <!-- MORE Filter -->
    <v-dialog v-model="moreFiltersDialog" max-width="500px">
      <v-card>
        <v-toolbar flat>
          <v-btn icon @click="moreFiltersDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>More filters</v-toolbar-title>
        </v-toolbar>
        <v-card-title>
          <span class="text-h5">Amenities</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row style="display: flex; align-items: center">
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Kitchen"
                    color="orange"
                    value="Kitchen"
                    hide-details
                    v-model="kitchen"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Air conditioning"
                    color="orange"
                    value="Air-condition"
                    hide-details
                    v-model="aircondition"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Private Washroom"
                    color="orange"
                    value="Private Bathroom"
                    v-model="privatewashroom"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Balcony"
                    color="orange"
                    value="Balcony"
                    v-model="balcony"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Dryer"
                    color="orange"
                    value="Dryer"
                    v-model="dryer"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Iron"
                    color="orange"
                    value="Iron"
                    v-model="iron"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Cot"
                    color="orange"
                    value="Cot"
                    hide-details
                    v-model="cot"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Heating"
                    color="orange"
                    value="Heating"
                    v-model="heating"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Washing machine"
                    color="orange"
                    value="Washing-Machine"
                    v-model="washingMachine"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Wifi"
                    color="orange"
                    value="Wi-fi"
                    v-model="wifi"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Indoor fireplace"
                    color="orange"
                    value="Indoor-Fireplace"
                    v-model="indoorfireplace"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Hair dryer"
                    color="orange"
                    value="Hair Dryer"
                    v-model="hairdryer"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Tv"
                    color="orange"
                    value="Tv"
                    v-model="tv"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="High chair"
                    color="orange"
                    value="High-chair"
                    v-model="highchair"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Water front"
                    color="orange"
                    value="Water-Font"
                    v-model="waterfront"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Beach front"
                    color="orange"
                    value="Beach-Font"
                    v-model="beachfront"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title>
          <span class="text-h5">Facilities</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row style="display: flex; align-items: center">
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Free parking on premises"
                    color="orange"
                    value="Free Parking"
                    v-model="freeparking"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Pool"
                    color="orange"
                    value="Pool"
                    v-model="pool"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Gym"
                    color="orange"
                    value="Gym"
                    v-model="gym"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Hot tub"
                    color="orange"
                    value="Hot_Tub"
                    v-model="hottub"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title>
          <span class="text-h5">Property Type</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row style="display: flex; align-items: center">
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="House"
                    color="orange"
                    value="House"
                    v-model="house"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Guest house"
                    color="orange"
                    value="Guest House"
                    v-model="guesthouse"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Bunglow"
                    color="orange"
                    value="Bunglow"
                    v-model="bunglow"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Chalet"
                    color="orange"
                    value="Chalet"
                    v-model="chalet"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Guest suite"
                    color="orange"
                    value="Guest suite"
                    v-model="guestsuite"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Cottage"
                    color="orange"
                    value="Cottage"
                    v-model="cottage"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Hotel"
                    color="orange"
                    value="Hotel"
                    hide-details
                    v-model="hotel"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Resort"
                    color="orange"
                    value="Resort"
                    v-model="resort"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Town house"
                    color="orange"
                    value="Town house"
                    v-model="townhouse"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Flat"
                    color="orange"
                    value="Flat"
                    v-model="flat"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Boutique hotel"
                    color="orange"
                    value="Boutique hotel"
                    v-model="boutiquehotel"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Cabin"
                    color="orange"
                    value="Cabin"
                    v-model="cabin"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Hostel"
                    color="orange"
                    value="Hostel"
                    v-model="hostel"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Loft"
                    color="orange"
                    value="Loft"
                    v-model="loft"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    label="Villa"
                    color="orange"
                    value="Villa"
                    v-model="villa"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="padding: 20px 15px">
          <v-spacer></v-spacer>
          <v-btn color="yellow darken-1" @click="filterByOpts()">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="signInOrUpDialog"
      width="500px"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card elevation="0">
        <v-toolbar flat elevation="0">
          <v-btn icon @click="signInOrUpDialog = false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false"> Save </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <app-sign-in-or-up @dismissDialog="dismissDialog"></app-sign-in-or-up>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import moment from 'moment';
import msgHelper from '../../utils/msg-helper';
import SignInOrUp from '../components/SignInOrUp.vue';
import { ValidationProvider } from 'vee-validate';
import EventBus from '../../utils/EventBus';
const _ = require('lodash');

export default {
  name: 'app-navbar',
  props: {
    pageRoute: String,
  },
  components: {
    'app-sign-in-or-up': SignInOrUp,
    ValidationProvider,
  },
  data() {
    return {
      filterDialog: false,
      city: '',
      selectedCities: [],
      signInOrUpDialog: false,
      search: '',
      countries: [],
      e6: 1,
      mobile: '',
      otp: '',
      otpBasedDialog: false,
      mobileDialCode: '',
      number: '',
      dateRange: [],
      overlay: false,
      adminLink: '',
      moreFiltersDialog: false,
      /* Amenities */
      kitchen: '',
      aircondition: '',
      privatewashroom: '',
      balcony: '',
      dryer: '',
      iron: '',
      cot: '',
      heating: '',
      washingMachine: '',
      wifi: '',
      indoorfireplace: '',
      hairdryer: '',
      tv: '',
      highchair: '',
      waterfront: '',
      beachfront: '',
      /*END*/
      house: '',
      guesthouse: '',
      bunglow: '',
      chalet: '',
      guestsuite: '',
      cottage: '',
      hotel: '',
      resort: '',
      townhouse: '',
      flat: '',
      boutiquehotel: '',
      cabin: '',
      hostel: '',
      loft: '',
      villa: '',
      freeparking: '',
      pool: '',
      gym: '',
      hottub: '',
    };
  },
  computed: {
    availWidth() {
      return window.screen.width;
    },
    dateRangeText() {
      return this.dateRange.join(' - ');
    },
    user() {
      return this.$store.state.user;
    },
    token() {
      return this.$store.state.token;
    },
    menus() {
      return [
        {
          title: 'Log In/Sign Up',
          href: '#',
          onClick: () => {
            this.signInOrUpDialog = true;
          },
          userLoggedInReq: false,
        },
        {
          title: 'Host Your Home',
          href: this.adminLink,
          onClick: () => {},
          userLoggedInReq: false,
        },
        {
          title: 'Host An Experience',
          href: this.adminLink,
          onClick: () => {},
          userLoggedInReq: false,
        },
        {
          title: 'Booking History',
          href: '#',
          onClick: () => {
            this.$router.push({
              name: 'BookingHistory',
            });
          },
          userLoggedInReq: true,
        },
        {
          title: 'Profile',
          href: '#',
          onClick: () => {
            this.$router.push({
              name: 'AccountSettings',
            });
          },
          userLoggedInReq: true,
        },
        {
          title: 'Sign Out',
          href: '#',
          onClick: async () => {
            await this.logout();
            this.$emit('reload');
          },
          userLoggedInReq: true,
        },
      ];
    },
  },
  created() {
    const isProd = process.env.VUE_APP_BUILD_MODE === 'prod';
    this.adminLink = isProd
      ? process.env.VUE_APP_PROD_ADMIN
      : process.env.VUE_APP_STAGING_ADMIN;
    this.fetchEventsList();
  },
  mounted() {
    EventBus.$on('openSignInOrUpDialog', () => {
      this.signInOrUpDialog = true;
    });

    EventBus.$on('openFilter', () => {
      this.moreFiltersDialog = true;
    });
  },
  methods: {
    filterByOpts() {
      const propertyType = _.without(
        [
          this.house,
          this.guesthouse,
          this.bunglow,
          this.chalet,
          this.guestsuite,
          this.cottage,
          this.hotel,
          this.resort,
          this.townhouse,
          this.flat,
          this.boutiquehotel,
          this.cabin,
          this.hostel,
          this.loft,
          this.villa,
        ],
        '',
        null,
        undefined
      );
      const facilities = _.without(
        [this.freeparking, this.pool, this.gym, this.hottub],
        '',
        null,
        undefined
      );
      const amenities = _.without(
        [
          this.kitchen,
          this.aircondition,
          this.privatewashroom,
          this.balcony,
          this.dryer,
          this.iron,
          this.cot,
          this.heating,
          this.washingMachine,
          this.wifi,
          this.indoorfireplace,
          this.hairdryer,
          this.tv,
          this.highchair,
          this.waterfront,
          this.beachfront,
        ],
        '',
        null,
        undefined
      );
      EventBus.$emit('updateFilter', {
        amenities,
        facilities,
        propertyType,
      });
      this.moreFiltersDialog = false;
    },
    async fetchEventsList() {
      this.preloader = true;
      try {
        const resp = (await this.$http.post('fetchPackageLocation')).data.data;
        this.packageImages = resp;
        this.countries = _.map(resp, (country) => {
          let payload = {
            name: country.country_name,
            states: _.map(country.city_name, (city) => {
              return { name: city };
            }),
          };
          return payload;
        });
      } catch (e) {
        console.log(e);
        msgHelper.showToast(
          'error',
          'Failed to get the locations, please try again later'
        );
      }
      this.preloader = false;
    },
    getCitiesByCountry(event) {
      this.country = '';
      this.country = event.name;
      this.selectedCities = event.states;
    },
    getCityName(event) {
      this.city = '';
      this.city = event.name;
    },
    navigateBackURL() {
      return this.$router.go(-1);
    },
    async searchCountry() {
      this.overlay = !this.overlay;
      // Start and End Date
      const startDate = this.dateRange[0];
      const endDate = this.dateRange[1];
      let start_date = '',
        end_date = '';
      if (startDate) {
        start_date = moment(startDate, 'YYYY-MM-DD');
        start_date = moment(startDate).unix();
      }
      if (endDate) {
        end_date = moment(endDate, 'YYYY-MM-DD');
        end_date = moment(end_date).unix();
      }
      let arr = [
        this.house,
        this.guesthouse,
        this.bunglow,
        this.chalet,
        this.guestsuite,
        this.cottage,
        this.hotel,
        this.resort,
        this.townhouse,
        this.flat,
        this.boutiquehotel,
        this.cabin,
        this.hostel,
        this.loft,
        this.villa,
      ];
      let temp_data = arr.filter((element) => {
        return element !== '';
      });
      let facilities = [this.freeparking, this.pool, this.gym, this.hottub];
      let facilities_data = facilities.filter((element) => {
        return element !== '';
      });
      let amenities = [
        this.kitchen,
        this.aircondition,
        this.privatewashroom,
        this.balcony,
        this.dryer,
        this.iron,
        this.cot,
        this.heating,
        this.washingMachine,
        this.wifi,
        this.indoorfireplace,
        this.hairdryer,
        this.tv,
        this.highchair,
        this.waterfront,
        this.beachfront,
      ];
      let amenities_data = amenities.filter((element) => {
        return element !== '';
      });

      const payload = {
        country: this.country,
        city: this.city,
        check_in_date: start_date,
        check_out_date: end_date,
        amenities: amenities_data,
        facilities: facilities_data,
        property_type: temp_data,
        deleted_at: 0,
      };

      try {
        this.overlay = true;
        const response = await this.$http.post('searchPackage', payload);
        const responseMsg = response.data.message;
        console.log('packages', response.data.data.length);
        if (response.status === 200) {
          let query = {};
          if (responseMsg === 'List of Packages Found') {
            query.search = 1;
          } else if (
            responseMsg ===
              'No Package Found! Here are Some Recommended Packages For You' ||
            responseMsg === 'Recommanded Packages Found'
          ) {
            query.search = 2;
          } else if (
            responseMsg === 'List of Check_in,Check_out packages found'
          ) {
            query.search = 3;
          } else {
            query.search = 0; // If not searched
            return;
          }

          const packages = response.data.data;
          this.$store.commit('setPackages', packages);
          if (this.$router.currentRoute.name == 'Explore') {
            this.$router.replace({
              name: 'Explore',
              params: { country_name: this.country },
              query,
            });
            window.location.reload();
          } else {
            this.$router.push({
              name: 'Explore',
              params: { country_name: this.country },
              query,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
      this.overlay = false;
    },
    dismissDialog() {
      this.signInOrUpDialog = false;
      this.$emit('reload');
    },
    reloadPage() {
      this.$router.replace({
        name: 'Dashboard',
      });
    },
    onSelect({ dialCode }) {
      this.mobileDialCode = dialCode;
    },
    async nextStepLogin() {
      const validationStats = await this.$refs.mobileNumber.validate();
      if (validationStats.valid) {
        this.e6 = 2;
        this.sendOtp();
      }
    },
    dismissOtpBasedDialog() {
      this.$refs.mobileNumber.reset();
      this.otpBasedDialog = false;
      this.e6 = 1;
      this.mobileDialCode = '';
      this.mobile = '';
    },
    async getCountryName(country_name) {
      this.registrationFlag = true;
      try {
        const res = await this.$http.post('fetchPackageByCountry', {
          country: country_name,
        });
        this.details = res.data.data;
        this.packageImages = [];
        // this.registrationFlag = false;
        this.list = res.data.data;
        this.storedPackages = this.list;
        for (let i = 0; i < this.list.length; i++) {
          this.packageImages.push(this.list[i]);
        }
        this.registrationFlag = false;
        console.log('packages ', this.packageImages);
      } catch (error) {
        let message = error.response.data.message;
        this.responsecode = error;
        this.$swal({
          title: 'Info!',
          text: message,
          icon: 'error',
        });
        this.registrationFlag = false;
      }
    },
    getcity12(event) {
      this.country = '';
      this.country = event.name;
      this.cityselect = event.states;
      console.log('country', this.country);
    },
    getcity13(event) {
      this.city = '';
      this.cityselect = event.name;
      console.log('city', this.cityselect);
      localStorage.setItem('city', this.cityselect);
    },

    getcity1(event) {
      console.log('city', event);
      // console.log("package", this.list);
      this.country = event;
      console.log('list', this.country);
    },
    getCity() {
      this.list = this.storedPackages;
      const cities = [];
      let i = 0;
      while (i < this.list.length) {
        const search = new RegExp(this.selectedcity.trim().toLowerCase(), 'g');
        if (this.list[i].package_title.trim().toLowerCase().match(search)) {
          cities.push(this.list[i]);
        }
        i++;
      }
      this.list = cities;
      this.storedCPackages = this.storedPackages;
    },
    async logout() {
      this.registrationFlag = true;
      try {
        await this.$http.post('logout', {
          user_id: this.user._id,
        });
        this.$store.commit('setUser', null);
        this.$store.commit('setToken', null);
        this.$store.commit('setPackageId', null);
        this.$store.commit('setPricing', {
          total: 0,
          noOfCustomers: 0,
          packagePrice: 0,
          daysCount: 0,
        });
        this.$store.commit('setPackageDetails', null);
        msgHelper.showToast(
          'success',
          'Your account has been logout successfully.'
        );

        this.registrationFlag = false;
      } catch (e) {
        msgHelper.showToast(
          'error',
          'Something went wrong. Please check your network'
        );
      }
      this.registrationFlag = false;
    },
    // Check if the User is valid and send otp
    sendOtp() {
      if (this.mobile === '') {
        msgHelper.showToast(
          'warning',
          'You must fill in all of the required fields'
        );
      } else {
        this.$http
          .post('loginCustomerUsingMobile', {
            mobile: `+${this.mobileDialCode}${this.mobile}`,
          })
          .then((res) => {
            msgHelper.showToast('success', res.data.message);
          })
          .catch((err) => {
            if (err.response) {
              msgHelper.showToast('error', err.response.data.message);
            } else {
              msgHelper.showToast(
                'error',
                'Something went wrong. Please try logging in later'
              );
            }
          });
      }
    },
    // Verify Otp provided by the user
    verifyOtp() {
      this.overlay = true;
      this.$http
        .post('verifyMobileOtp', {
          mobile: '+' + this.mobileDialCode + this.mobile,
          otp: Number(this.otp),
        })
        .then((res) => {
          this.$store.commit('setUser', res.data.user);
          this.$store.commit('setToken', res.data.token);
          msgHelper.showToast('success', res.data.message);
          this.overlay = false;
        })
        .catch((err) => {
          if (err.response) {
            msgHelper.showToast('error', err.response.data.message);
          } else {
            msgHelper.showToast(
              'error',
              'Something went wrong. Please try logging in later'
            );
          }
          this.overlay = false;
        });
    },
  },
};
</script>
<style scoped src="../assets/css/style.css"></style>
