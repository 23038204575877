import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});
export default new Vuex.Store({
  state: {
    paypal_id:
      process.env.VUE_APP_BUILD_MODE === 'prod'
        ? process.env.VUE_APP_PAYPAL_PROD
        : process.env.VUE_APP_PAYPAL_DEV,
    packageDetails: null,
    pricing: {
      total: 0,
      noOfCustomers: 0,
      packagePrice: 0,
      daysCount: 0,
    },
    user: null,
    token: null,
    packageId: null,
  },
  mutations: {
    setPackages(state, packages) {
      state.packages = packages;
    },
    setToken(state, token) {
      state.token = token;
    },
    setPackageId(state, packageId) {
      state.packageId = packageId;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setPricing(state, payload) {
      state.pricing = { ...payload };
    },
    setPackageDetails(state, payload) {
      state.packageDetails = payload;
    },
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin],
});
