import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import lodash from 'lodash';
import moment from 'moment';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueCountryCode from 'vue-country-code-select';
require('dotenv').config();

Vue.config.productionTip = false;
Vue.use(lodash);
Vue.use(VueCountryCode);

const baseURL = axios.create({
  baseURL:
    process.env.VUE_APP_BUILD_MODE === 'prod'
      ? process.env.VUE_APP_ENDPOINT_PROD
      : process.env.VUE_APP_ENDPOINT_STAGING,
});
Vue.prototype.$http = baseURL;

Vue.prototype._ = lodash;
Vue.prototype.$moment = moment;
// Globally available
window.app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
