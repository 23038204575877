import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  history: false,
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: () => import('../views/Dashboard.vue'),
    },
    {
      path: '/reset-password',
      name: 'Resetpassword',
      component: () => import('../views/Resetpassword.vue'),
    },
    {
      path: '/booking-history',
      name: 'BookingHistory',
      component: () => import('../views/BookingHistory.vue'),
    },
    {
      path: '/account-settings',
      name: 'AccountSettings',
      component: () => import('../views/AccountSettings.vue'),
    },
    {
      path: '/personal-info',
      name: 'UpdateProfile',
      component: () => import('../views/UpdateProfile.vue'),
    },
    {
      path: '/payment/:packageId',
      name: 'Payment',
      component: () => import('../views/Payment.vue'),
    },
    {
      path: '/package-details/:packageId',
      name: 'Package Details',
      component: () => import('../views/Packagedetails.vue'),
    },
    {
      path: '/explore/:country_name',
      name: 'Explore',
      component: () => import('../views/Explore.vue'),
    },
    {
      path: '/terms',
      name: 'Terms',
      component: () => import('../views/Terms.vue'),
    },
    {
      path: '/make-payment',
      name: 'Make Payment',
      component: () => import('../views/ProcessPayment.vue'),
    },
  ],
});
export default router;
